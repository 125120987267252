import React from "react"
import RoundCrossButton from "./RoundCrossButton"

const ImageModal = ({ image, alt, closeModal }) => {
  return (
    <div
      className={`modal page__position--full active`}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="modal__overflow page__position--full"
        onClick={closeModal}
      ></div>
      <div
        style={{
          zIndex: 100,
          marginLeft: 70,
          marginRight: 70,
        }}
      >
        <RoundCrossButton onClick={closeModal} />

        <img src={image} alt={alt} title={alt} style={{ width: "100%" }} />
      </div>
    </div>
  )
}

export default ImageModal
