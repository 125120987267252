import React, { useContext, useState } from "react"
import { navigate, graphql } from "gatsby"
import Layout from "../components/Layout.en"
import PageTitle from "../components/PageTitle"
import GradientImage from "../images/gradient-big.png"
import SchemeH from "../images/supplier-m-scheme-hz-e.png"
import SchemeV from "../images/supplier-m-scheme-v-e.png"
import RiskSchemeS from "../images/cyber-risk-small.png"
import RiskSchemeL from "../images/cyber-risk-big.png"
import { GlobalDispatchContext } from "../context/GlobalContextProvider"
import ImageModal from "../components/ImageModal"

const SupplierRiskManagement = ({ data }) => {
  const dispatch = useContext(GlobalDispatchContext)

  const [isImageModalOpen, setIsImageModalOpen] = useState(false)

  return (
    <React.Fragment>
      <PageTitle title={"Supplier Risk Management Guide"} />
      <Layout>
        <div className="supplier-risk-management-gradientimg--wrapper">
          <img src={GradientImage} alt="Gradient" title="Gradient" />
        </div>
        <div className="page__wrap">
          <div className="breadcrumbs">
            <ul>
              <li>
                <a onClick={() => navigate("/en")}>Home</a>
              </li>
              <li>
                <a>Supplier Risk Management Guide</a>
              </li>
            </ul>
          </div>

          <div className="supplier-risk-management">
            <h1>Supplier Risk Management Guide</h1>

            <div className="supplier-risk-management__block">
              <div
                dangerouslySetInnerHTML={{
                  __html: data.datoCmsSupplierriskmanagement.introduction,
                }}
              ></div>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.datoCmsSupplierriskmanagement.sectors,
                }}
              ></div>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.datoCmsSupplierriskmanagement.outroduction,
                }}
              ></div>
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.datoCmsSupplierriskmanagement.functions,
                  }}
                ></div>
                <div>
                  <div
                    style={{
                      textAlign: "center",
                      minWidth: 400,
                      position: "relative",
                    }}
                  >
                    <img
                      src={RiskSchemeS}
                      className="zoom__img--gov"
                      alt="Lieferantenrisikomanagement"
                      title="Lieferantenrisikomanagement"
                    />
                    <div>
                      <div
                        className="zoom__img__wrapper--gov"
                        onClick={() => setIsImageModalOpen(true)}
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <svg
                          width="29"
                          height="28"
                          viewBox="0 0 29 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_213_11781)">
                            <path
                              d="M20.8511 19.6593C22.6383 17.5741 23.8298 14.794 23.8298 11.8153C23.8298 5.26209 18.4681 -0.0996094 11.9149 -0.0996094C5.3617 -0.0996094 0 5.36138 0 11.9146C0 18.4678 5.3617 23.8295 11.9149 23.8295C15.1915 23.8295 18.0709 22.5387 20.2553 20.4536L27.8014 27.9997L28.4965 27.3046L20.8511 19.6593ZM11.9149 22.8366C5.85816 22.8366 0.992908 17.872 0.992908 11.9146C0.992908 5.95713 5.85816 0.992589 11.9149 0.992589C17.9716 0.992589 22.8369 5.85784 22.8369 11.9146C22.8369 17.9713 17.8723 22.8366 11.9149 22.8366Z"
                              fill="white"
                            />
                            <path
                              d="M17.1773 11.4183V12.4112H12.4113V17.1772H11.4184V12.4112H6.65247V11.4183H11.4184V6.65234H12.4113V11.4183H17.1773Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_213_11781">
                              <rect width="28.3972" height="28" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 25,
                    }}
                  >
                    <a
                      className="white-button-large page__btn page__btn--blue-border"
                      onClick={() => dispatch({ type: "OPEN_CONTACT_MODAL" })}
                    >
                      Request Access to the Supplier Risk Platform
                    </a>
                  </div>
                </div>
              </div>
              {/* <div
                dangerouslySetInnerHTML={{
                  __html: data.datoCmsSupplierriskmanagement.moreInfo,
                }}
              ></div> */}
              <div>
                <div
                  className="main__trust--block"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    maxWidth: "unset",
                    textAlign: "center",
                  }}
                >
                  <h2 style={{ paddingBottom: 10 }}>
                    Suplier Risk Management Process
                  </h2>
                </div>
                <div className="supplier-risk-management__block--container">
                  <div
                    style={{
                      fontSize: 15,
                      lineHeight: "170%",
                      fontWeight: 400,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: data.datoCmsSupplierriskmanagement.plan,
                    }}
                  ></div>
                  <div className="supplier-risk-management__block--container--img">
                    <img
                      src={SchemeH}
                      alt="Lieferantenrisikomanagement"
                      title="Lieferantenrisikomanagement"
                    />
                    <img
                      src={SchemeV}
                      alt="Lieferantenrisikomanagement"
                      title="Lieferantenrisikomanagement"
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 25,
                }}
              >
                <a
                  className="blue-button-large page__btn page__btn--blue "
                  onClick={() => dispatch({ type: "OPEN_DOWNLOAD_MODAL" })}
                >
                  Supply Risk Management Handbook & Tool
                </a>
              </div>
            </div>
          </div>
        </div>

        {isImageModalOpen && (
          <ImageModal
            image={RiskSchemeL}
            alt="test"
            closeModal={() => setIsImageModalOpen(false)}
          />
        )}
      </Layout>
    </React.Fragment>
  )
}

export default SupplierRiskManagement

export const query = graphql`
  query ($locale: String!) {
    datoCmsSupplierriskmanagement(locale: { eq: $locale }) {
      introduction
      outroduction
      plan
      functions
      moreInfo
      sectors
      planFile {
        url
        mimeType
        size
      }
    }
  }
`
